// Firestore queries for Jobs collection
import { query, where } from 'firelordjs';

import { compact } from 'lodash';

import { getJobAssignments } from '@/lib/firebase/db/collections';
import { getReaderCompaniesConstraint } from '@/lib/firebase/db/helpers';
import { UserDoc } from '@/lib/firebase/db/metaTypes';

/**
 * Constructs a Firestore query for fetching jobs based on a specific date.
 * @function
 * @param {Object} options - The options object.
 * @param {UserDoc} [options.userDoc] - The user document containing reader companies information.
 * @param {Date} options.date - The date to filter the jobs.
 * @returns {Query} A Firestore query for fetching jobs based on the specified date.
 */
export const jobsAssignmentsLeafNodesByJobQuery = ({
  jobId,
  userDoc,
}: {
  jobId: string;
  userDoc?: UserDoc;
}) => {
  const constraints = compact([
    getReaderCompaniesConstraint(userDoc),
    where('isLeafNode', '==', true),
  ]);
  return query(getJobAssignments(jobId), ...constraints);
};
