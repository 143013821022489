// Define a reference to each Collection or SubCollection
// https://firelordjs.com/api/metatype
import { getFirelord } from 'firelordjs';

import {
  Company,
  CompanyBroker,
  CompanyCustomTag,
  DispatchSchedule,
  Job,
  JobAssignment,
  OperatorTruck,
  Site,
  Task,
  User,
} from '@/lib/firebase/db/metaTypes';
import { db } from '@/lib/firebase/firestore';

export const Users = getFirelord<User>(db, 'users');

export const Jobs = getFirelord<Job>(db, 'Job');

// JobAssignments is a subcollection of Job collection
export const JobAssignments = getFirelord<JobAssignment>(
  db,
  'Job',
  'JobAssignments'
);
// Obtain the reference to JobAssignments collection from a single Job
export const getJobAssignments = (jobId: string) =>
  JobAssignments.collection(jobId);

export const Tasks = getFirelord<Task>(db, 'Task');

export const Companies = getFirelord<Company>(db, 'Company');

export const CompanyBrokers = getFirelord<CompanyBroker>(
  db,
  'Company',
  'CompanyBrokers'
);
export const getCompanyBrokers = (companyId: string) =>
  CompanyBrokers.collection(companyId);

export const DispatchSchedules = getFirelord<DispatchSchedule>(
  db,
  'DispatchSchedule'
);

export const Sites = getFirelord<Site>(db, 'Site');

export const OperatorTrucks = getFirelord<OperatorTruck>(db, 'OperatorTruck');

export const CompanyCustomTags = getFirelord<CompanyCustomTag>(
  db,
  'Company',
  'CompanyCustomTags'
);
export const getCompanyCustomTags = (companyId: string) =>
  CompanyCustomTags.collection(companyId);
