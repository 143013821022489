// Helper functions to manage Date values
// date-fns reference: https://date-fns.org/v3.6.0/docs
import {
  endOfDay,
  endOfWeek,
  format,
  isSameDay,
  startOfDay,
  startOfWeek,
} from 'date-fns';

import { compact } from 'lodash';

export { addDays, addMonths, getDaysInMonth, subMonths } from 'date-fns';

const TIME_FORMAT_FRIENDLY = 'hh:mm a';

const DATE_FORMAT_ISO = 'yyyy-MM-dd';

/**
 * Returns the time string representation as (HH:MM) format of the Date object.
 */
export function getTimeStr(this: Date) {
  return format(this, TIME_FORMAT_FRIENDLY);
}

/**
 * Returns the date string representation as (YYYY-MM-DD) format of the Date object.
 */
export function getDateISOStr(this: Date) {
  return format(this, DATE_FORMAT_ISO);
}

// Set the day that week starts at (0: Sunday, 1: Monday, etc)
export const WEEK_START_DAY_NUMBER = 0;

export const daysLabels = compact([
  !WEEK_START_DAY_NUMBER && 'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  !!WEEK_START_DAY_NUMBER && 'Sun',
]);

export function getStartOfWeek(date: Date = new Date()) {
  return startOfWeek(date, { weekStartsOn: WEEK_START_DAY_NUMBER });
}

export function getEndOfWeek(date: Date = new Date()) {
  return endOfWeek(date, { weekStartsOn: WEEK_START_DAY_NUMBER });
}

export function getStartOfDay(date: Date = new Date()) {
  return startOfDay(date);
}

export function getEndOfDay(date: Date = new Date()) {
  return endOfDay(date);
}

export function getIsSameDay(dateA: Date, dateB: Date) {
  return isSameDay(dateA, dateB);
}
