import { uniqBy } from 'lodash';

import { firestore } from '@/lib/firebase';
import { companyBrokersByBrokerCompanyIdQuery } from '@/lib/firebase/db/queries';

export async function getClientOptionsForBrokerCompany(
  brokerCompanyId: string
) {
  const companyBrokerDocs = await firestore
    .getDocs(companyBrokersByBrokerCompanyIdQuery(brokerCompanyId))
    .then((querySnapshot) => querySnapshot.docs);
  const options = companyBrokerDocs.map((companyBrokerDoc) => ({
    id: companyBrokerDoc.id,
    name: companyBrokerDoc.data().businessName,
    clientId: companyBrokerDoc.data().companyId,
  }));
  return uniqBy(options, 'clientId');
}
