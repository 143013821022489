// Re-export firestore utility functions from firelordjs to avoid referencing firelordjs in components and utilities
// Example:
// import { firestore } from '@/lib/firebase';
// firestore.getDoc(Users.doc( '<docId>'))
import {
  connectFirestoreEmulator,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
} from 'firelordjs';

import {
  filterSoftDeletedDocs,
  getDocWrapper,
  getDocsWrapper,
} from '@/lib/firebase/db/read';
import { save, softDelete } from '@/lib/firebase/db/save';
import { firebaseApp } from '@/lib/firebase/firebase';

import { firebaseConfig, useEmulators } from '@/lib/env';

// Initialize Firestore instance
export const db = getFirestore(firebaseApp);
if (useEmulators) {
  connectFirestoreEmulator(
    db,
    'localhost',
    firebaseConfig.emulators.firestore.port
  );
}

// Export utility functions
const utilityFunctions = {
  getDoc: getDocWrapper,
  getDocs: getDocsWrapper,
  onSnapshot: onSnapshot,
  // FIXME: onSnapshotWrapper causes type conflict between QuerySnapshot and DocumentSnapshot
  // onSnapshot: onSnapshotWrapper,
  filterSoftDeletedDocs: filterSoftDeletedDocs,
  // Include original methods to include deleted docs in query results
  getDocIncludeDeleted: getDoc,
  getDocsIncludeDeleted: getDocs,
  // Save method to create or update documents
  save,
  delete: softDelete,
};
export default utilityFunctions;
