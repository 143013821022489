// Abstract function to create or update any firestore document
// source: https://firelordjs.com/api/AbstractMetaTypeCreator
import {
  CollectionReference,
  DocumentReference,
  addDoc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from 'firelordjs';
import { AbstractMetaTypeCreator } from 'firelordjs';

import {
  CommonFields,
  DeletedFields,
  TimestampsFields,
} from '@/lib/firebase/db/@types';
import { UserDoc } from '@/lib/firebase/db/metaTypes';

/**
 * Saves data to a Firestore document. This function abstracts the creation and updating of documents.
 * If the reference provided is a document reference, the document will be updated.
 * If the reference is a collection reference, a new document will be created in that collection.
 *
 * @template T Extends AbstractMetaType, indicating the structure of the document to be saved.
 * @param {CollectionReference<T> | DocumentReference<T>} ref - A reference to the Firestore document to be updated or collection where the new document should be created.
 * @param {Partial<T['writeFlatten']>} data - The data to be saved. It should conform to the structure defined by T['writeFlatten'].
 * @param {UserDoc} user - The current user document, used to set `createdBy` and `modifiedBy` fields in the document.
 * @returns {Promise<DocumentSnapshot<T>>} A promise that resolves to the document snapshot when the operation is complete.
 */
export async function save<T extends AbstractMetaType>(
  ref: CollectionReference<T> | DocumentReference<T>,
  data: Partial<T['writeFlatten']>,
  user: UserDoc
) {
  const docRef = await (ref.type === 'document'
    ? update({ ref, data, user }).then(() => ref)
    : create({ ref, data, user }));
  return getDoc(docRef);
}

type AbstractMetaType = AbstractMetaTypeCreator<CommonFields>;

type FirestoreCreateParams<T extends AbstractMetaType> = {
  ref: CollectionReference<T>;
  data: Partial<T['write']>;
  user: UserDoc;
};

export function create<T extends AbstractMetaType>({
  ref,
  data,
  user,
}: FirestoreCreateParams<T>) {
  const createTimestamps: Partial<TimestampsFields> = {
    created: serverTimestamp(),
    createdBy: user.get('nickname'),
    modified: serverTimestamp(),
    modifiedBy: user.get('nickname'),
  };

  const mixedData = {
    ...data,
    timestamps: createTimestamps,
  };

  return addDoc(ref, mixedData as T['write']);
}

type FirestoreUpdateParams<T extends AbstractMetaType> = {
  ref: DocumentReference<T>;
  data: Partial<T['writeFlatten']>;
  user: UserDoc;
};

export function update<T extends AbstractMetaType>({
  ref,
  data,
  user,
}: FirestoreUpdateParams<T>) {
  const updatedTimestamps: Partial<TimestampsFields> = {
    modified: serverTimestamp(),
    modifiedBy: user.get('nickname'),
  };

  // it is not safe enough to type object with spread operator because spread does not trigger excess property.
  // check https://stackoverflow.com/questions/59318739/is-there-an-option-to-make-spreading-an-object-strict
  // what you want to do is make sure 'updatedTimestamps' type is correct
  const mixedData = {
    ...data,
    timestamps: {
      ...updatedTimestamps,
    },
  };

  return updateDoc(
    ref,
    //@ts-expect-error
    mixedData
  );
}

/**
 * Marks a Firestore document as soft-deleted by adding a 'deleted' metadata field to it.
 * This function does not actually delete the document from Firestore but instead updates it with
 * metadata indicating that it has been deleted, who deleted it, and when it was deleted.
 * This approach allows for the data to be retained and possibly restored or queried for audit purposes.
 *
 * @template T Extends AbstractMetaType, indicating the structure of the document to be soft-deleted.
 * @param {DocumentReference<T>} ref - A reference to the Firestore document to be soft-deleted.
 * @param {UserDoc} user - The user document of the user performing the soft-delete operation. This is used to record who initiated the delete.
 * @returns {Promise<void>} A promise that resolves when the document has been successfully updated with the soft-delete metadata.
 */
export function softDelete<T extends AbstractMetaType>(
  ref: DocumentReference<T>,
  user: UserDoc
) {
  const deletedMetadata: DeletedFields = {
    deletedByUserName: user.get('nickname') || 'cloudFunction',
    deletedByUserId: user.id,
    deletedDatetime: serverTimestamp(),
  };
  return save(
    ref,
    { deleted: deletedMetadata } as Partial<T['writeFlatten']>,
    user
  );
}
