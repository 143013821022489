// Helper functions for Users collection
import { User as AuthUser } from 'firebase/auth';
import { Unsubscribe } from 'firelordjs';

import { firestore } from '@/lib/firebase';
import { Users } from '@/lib/firebase/db/collections';
import { UserDoc } from '@/lib/firebase/db/metaTypes';

/**
 * A function that either fetches the user document once or sets up a real-time listener for the user document,
 * depending on whether an onChange callback is provided.
 *
 * If no onChange callback is provided, the function fetches the user document based on the authenticated user's UID
 * and returns a promise that resolves with the user document snapshot.
 *
 * If an onChange callback is provided, the function sets up a real-time listener for the user document.
 * Whenever the document changes, the onChange callback is called with the updated user document snapshot.
 * The function then returns an unsubscribe function that can be called to stop listening for changes.
 *
 * @param {AuthUser} authUser - The authenticated user object containing the UID to locate the user document.
 * @param {(user: UserDoc) => void} [onChange] - Optional. A callback function that is called with the user document snapshot whenever the document changes.
 * @returns {Promise<UserDoc> | Unsubscribe} If no onChange callback is provided, returns a promise that resolves with the user document snapshot.
 *                                           If an onChange callback is provided, returns an unsubscribe function to stop listening for document changes.
 */
export function loadUserDocument(authUser: AuthUser): Promise<UserDoc>;
export function loadUserDocument(
  authUser: AuthUser,
  onChange: (user: UserDoc) => void
): Unsubscribe;

export function loadUserDocument(
  authUser: AuthUser,
  onChange?: (user: UserDoc) => void
) {
  const userDocRef = Users.doc(authUser.uid);
  if (!onChange) {
    return firestore
      .getDoc(userDocRef)
      .then(checkAndReplaceUserWhenImpersonating);
  } else {
    const unsubscribe = firestore.onSnapshot(
      userDocRef,
      async (userDocSnapshot_) => {
        const userDocSnapshot =
          await checkAndReplaceUserWhenImpersonating(userDocSnapshot_);
        onChange(userDocSnapshot);
      }
    );
    return unsubscribe;
  }
}

/**
 * Checks if the current user is impersonating another user and, if so, retrieves the impersonated user's document.
 * This function examines the provided user document for an impersonation indicator. If the user is not impersonating anyone,
 * it simply returns the original user document. If the user is impersonating another user, it fetches and returns the document
 * of the impersonated user.
 *
 * @param {UserDoc} userDoc - The document snapshot of the currently authenticated user, which might contain impersonation details.
 * @returns {Promise<UserDoc>} A promise that resolves with the document snapshot of the user being impersonated, if impersonation is active;
 *                             otherwise, it resolves with the original user document.
 */
async function checkAndReplaceUserWhenImpersonating(
  userDoc: UserDoc
): Promise<UserDoc> {
  const impersonatedUserId = userDoc.get(
    'writeNever.impersonatedUserObj.impersonatedUserId'
  );
  const isImpersonating = !!impersonatedUserId;
  if (!isImpersonating) {
    return userDoc;
  }
  const impersonatedUserDoc = await firestore.getDoc(
    Users.doc(impersonatedUserId)
  );
  return impersonatedUserDoc;
}

/**
 * Extracts the company ID from the user document.
 *
 * @param {UserDoc} userDoc - The user document from which to extract the company ID.
 * @returns {string | undefined} The company ID if available, otherwise undefined.
 */
export function getUserCompanyId(userDoc: UserDoc): string {
  return userDoc.data()?.writeNever?.companyId || '';
}

/**
 * Updates a user's document in Firestore to set a new default dispatch schedule ID in the user's preferences.
 * This function specifically targets the 'preferences.dispatchSchedule.defaultDispatchScheduleId' field in the user document.
 *
 * @param {UserDoc} userDoc - The document snapshot of the user whose default dispatch schedule is to be updated.
 * @param {string} dispatchScheduleId - The ID of the dispatch schedule to set as the user's new default or an empty string to clear the current value.
 * @returns {Promise<UserDoc>} A promise that resolves when the user's document has been successfully updated with the new default dispatch schedule ID.
 */
export function updateUserDefaultDispatchSchedule(
  userDoc: UserDoc,
  dispatchScheduleId: string
) {
  return firestore.save(
    userDoc.ref,
    {
      'preferences.dispatchSchedule.defaultDispatchScheduleId':
        dispatchScheduleId,
    },
    userDoc
  );
}

/**
 * Retrieves the saved search filters for operators from a user document.
 * This function extracts the user's saved search filters for operators from their preferences within the user document.
 * It's designed to easily access a user's customized search preferences, specifically for filtering operator searches,
 * enhancing user experience by quickly applying their preferred filters.
 *
 * @param {UserDoc} userDoc - The document of the user from which to retrieve saved search filters.
 * @returns {Array} An array of saved search filters for operators, or an empty array if no filters are saved.
 */
export function getUserSavedSearchFilters(userDoc: UserDoc) {
  return userDoc.get('preferences.search.operators.filters') || [];
}

/**
 * Updates the user document with new search filters for operators.
 * This function saves a new set of search filters for operators into the user's preferences within their document.
 * It allows for dynamic updating of user preferences, specifically tailored to enhancing the search functionality
 * by storing user-defined filters. This can improve the user experience by personalizing search results based on
 * the user's saved criteria.
 *
 * @param {UserDoc} userDoc - The document of the user where the search filters will be saved.
 * @param {string[]} filters - An array of filters to be saved into the user's preferences. (e.g. ['operator.role:"broker"', 'operator.role:"operatorOwner"'])
 * @returns {Promise<UserDoc>} A promise that resolves once the operation is complete, indicating the user's search
 * filters have been successfully updated.
 */
export function saveUserSearchFilters(userDoc: UserDoc, filters: string[]) {
  return firestore.save(
    userDoc.ref,
    {
      'preferences.search.operators.filters': filters,
    },
    userDoc
  );
}
