// Custom Logging utility functions, acts in replacement for global console methods
import { noop } from 'lodash';

import { getGlobal } from '@/lib/helpers/functions';

const globalConsole = getGlobal().console;

const log = {
  info: (...args: any[]) => (globalConsole.info || noop).apply(null, args),
  debug: (...args: any[]) => (globalConsole.debug || noop).apply(null, args),
  warn: (...args: any[]) => (globalConsole.warn || noop).apply(null, args),
  error: (...args: any[]) => (globalConsole.error || noop).apply(null, args),
};

export default log;
