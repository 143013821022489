// Firebase client sdk initialization
// source: https://github.com/firebase/friendlyeats-web/blob/master/nextjs-end/src/lib/firebase/firebase.js
import { FirebaseApp, getApps, initializeApp } from 'firebase/app';
import {
  Auth,
  connectAuthEmulator,
  getAuth,
  signInWithCustomToken,
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';

import { firebaseConfig, useEmulators } from '@/lib/env';
import log from '@/lib/logger';

export const firebaseApp = getApps()[0] || initializeApp(firebaseConfig);

let firebaseAuth: Auth;
if (useEmulators) {
  firebaseAuth = getAuth();
  connectAuthEmulator(firebaseAuth, getEmulatorUrl('auth'), {
    disableWarnings: true,
  });
} else {
  firebaseAuth = getAuth(firebaseApp);
}
export const auth = firebaseAuth;

export const storage = getStorage(firebaseApp);

export async function getAuthenticatedAppForUser(session?: string) {
  if (typeof window !== 'undefined') {
    // Client-Side
    log.info('client: ', firebaseApp);

    return { app: firebaseApp, user: auth.currentUser?.toJSON() };
  }

  // FIXME: Build error when importing auth module in client side. May need to move it to its own firebase-admin setup file.
  // Server-Side
  // log.info('Running on server side');
  // const { initializeApp: initializeAdminApp, getApps: getAdminApps } =
  //   await import('firebase-admin/app');

  // const { getAuth: getAdminAuth } = await import('firebase-admin/auth');

  // const { credential } = await import('firebase-admin');

  // const ADMIN_APP_NAME = 'firebase-frameworks';
  // const adminApp =
  //   getAdminApps().find((it) => it.name === ADMIN_APP_NAME) ||
  //   initializeAdminApp(
  //     {
  //       credential: credential.applicationDefault(),
  //     },
  //     ADMIN_APP_NAME
  //   );

  // const adminAuth = getAdminAuth(adminApp);
  // const noSessionReturn = { app: null, currentUser: null };

  // if (!session) {
  //   // if no session cookie was passed, try to get from next/headers for app router
  //   session = await getAppRouterSession();

  //   if (!session) return noSessionReturn;
  // }

  // const decodedIdToken = await adminAuth.verifySessionCookie(session);

  // const app = initializeAuthenticatedApp(decodedIdToken.uid);
  // const serverAuth = getAuth(app);

  // // handle revoked tokens
  // const isRevoked = !(await adminAuth
  //   .verifySessionCookie(session, true)
  //   .catch((e) => log.error(e.message)));
  // if (isRevoked) return noSessionReturn;

  // // authenticate with custom token
  // if (serverAuth.currentUser?.uid !== decodedIdToken.uid) {
  //   const customToken = await adminAuth
  //     .createCustomToken(decodedIdToken.uid)
  //     .catch((e) => log.error(e.message));

  //   if (!customToken) return noSessionReturn;

  //   await signInWithCustomToken(serverAuth, customToken);
  // }
  // const currentUser = serverAuth.currentUser?.toJSON();
  // log.info('server: ', { app, currentUser });
  // return { app, currentUser: serverAuth.currentUser };
}

async function getAppRouterSession() {
  // dynamically import to prevent import errors in pages router
  const { cookies } = await import('next/headers');

  try {
    return cookies().get('__session')?.value;
  } catch (error) {
    // cookies() throws when called from pages router
    return undefined;
  }
}

function initializeAuthenticatedApp(uid: string) {
  const random = Math.random().toString(36).split('.')[1];
  const appName = `authenticated-context:${uid}:${random}`;

  const app = initializeApp(firebaseConfig, appName);

  return app;
}

function getEmulatorUrl(service: 'auth' | 'firestore' | 'hosting') {
  return `http://localhost:${firebaseConfig.emulators[service].port}`;
}
