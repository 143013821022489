// Firestore queries for CompanyBrokers collection
import { orderBy, query, where } from 'firelordjs';

import { compact } from 'lodash';

import { CompanyBrokers } from '@/lib/firebase/db/collections';

export const companyBrokersByBrokerCompanyIdQuery = (
  brokerCompanyId: string
) => {
  const constraints = compact([
    where('brokerCompany.id', '==', brokerCompanyId),
    orderBy('businessName', 'asc'),
  ]);
  return query(CompanyBrokers.collectionGroup(), ...constraints);
};
