// Firestore queries for OperatorTrucks collection
import { limit, query, where } from 'firelordjs';

import { compact } from 'lodash';

import { OperatorTrucks } from '@/lib/firebase/db/collections';

import { UserRole } from '@/lib/helpers/userRoles';

/**
 * Constructs a Firestore query to find the truck document associated with either
 * a specific user ID or email, with validation to ensure at least one identifier is provided.
 *
 * @param {Object} params - The search parameters, which must include at least a user ID or an email.
 * @param {string} [params.userId] - The unique identifier of the user, optional if email is provided.
 * @param {string} [params.email] - The email of the user, optional if userId is provided.
 * @returns {Query} A Firestore query object configured to find the truck document based on the provided user criteria.
 * @throws {Error} Throws an error if neither a userId nor an email is provided.
 */
export const operatorTruckByUserIdOrEmailQuery = ({
  userId,
  email,
}: {
  userId?: string;
  email?: string;
}) => {
  if (!userId && !email) {
    throw new Error('Either userId or email is required');
  }
  const constraints = compact([
    !!userId && where('operator.userId', '==', userId),
    !!email && !userId && where('operator.email', '==', email),
    limit(1),
  ]);

  return query(OperatorTrucks.collection(), ...constraints);
};

/**
 * Constructs a Firestore query to retrieve a single operator truck document based on user roles and company ID.
 *
 * @param {Object} params - The parameters for constructing the query.
 * @param {UserRole[]} params.userRoles - An array of user roles to include in the query. The function will
 *                                        look for operator trucks where the operator's role is within this array.
 * @param {string} params.companyId - The ID of the company to which the operator trucks must belong.
 * @returns {Query} A Firestore query object configured to find the first operator truck document that matches
 *                  the specified user roles within the given company.
 */
export const operatorTruckByUserRolesAndCompanyIdQuery = ({
  userRoles,
  companyId,
}: {
  userRoles: UserRole[];
  companyId: string;
}) => {
  const constraints = compact([
    where('operator.role', 'in', userRoles),
    where('company.companyId', '==', companyId),
    limit(1),
  ]);

  return query(OperatorTrucks.collection(), ...constraints);
};
