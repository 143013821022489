// Firestore queries for CompanyCustomTags collection
import { orderBy, query, where } from 'firelordjs';

import { compact } from 'lodash';

import { CompanyCustomTags } from '@/lib/firebase/db/collections';

/**
 * Constructs a Firestore query to retrieve custom tags created by a specific company.
 * This function generates a query targeting a collection group of company custom tags, filtered by the creator's
 * company ID. It's designed to facilitate the retrieval of custom tags that are specific to a particular company,
 * allowing for a more tailored data fetch based on company affiliation.
 *
 * @param {Object} params - The parameters for the query, specifically the creator's company ID.
 * @param {string} params.creatorCompanyId - The ID of the company that created the custom tags.
 * @returns {Query} A Firestore query object configured to find custom tags by the creator's company ID.
 */
export const customTagsByCreatorCompanyQuery = ({
  creatorCompanyId,
}: {
  creatorCompanyId: string;
}) => {
  const constraints = compact([
    where('creatorCompanyId', '==', creatorCompanyId),
  ]);
  return query(CompanyCustomTags.collectionGroup(), ...constraints);
};
