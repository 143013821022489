import { limit, query, where } from 'firelordjs';

import { compact } from 'lodash';

import { Sites } from '@/lib/firebase/db/collections';

/**
 * Constructs a Firestore query to search for site documents by name using a provided search term.
 *
 * The search is case-sensitive and looks for sites where the name starts with the given search term.
 *
 * @param {Object} params - The search parameters.
 * @param {string} params.nameSearchTerm - The term to search for in site names.
 * @returns {Query} A Firestore query object configured to find sites matching the search term.
 */
export const sitesSearchByNameQuery = ({
  nameSearchTerm,
}: {
  nameSearchTerm: string;
}) => {
  const constraints = compact([
    where('name', '>=', nameSearchTerm),
    where('name', '<', nameSearchTerm + '\uf8ff'),
    limit(10),
  ]);
  return query(Sites.collection(), ...constraints);
};
