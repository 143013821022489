// Firebase Auth utility functions
import {
  GoogleAuthProvider,
  NextOrObserver,
  User,
  UserCredential,
  onAuthStateChanged as _onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';

import { loadUserDocument } from '@/lib/firebase/db/helpers';
import { UserDoc } from '@/lib/firebase/db/metaTypes';
import { auth } from '@/lib/firebase/firebase';

import { authCheckCloudFunction, firebaseConfig } from '@/lib/env';
import log from '@/lib/logger';

export function getCurrentUserDoc(): false | Promise<UserDoc> {
  return !!auth.currentUser && loadUserDocument(auth.currentUser);
}

export function onAuthStateChanged(cb: NextOrObserver<User>) {
  return _onAuthStateChanged(auth, cb);
}

function signInOrSignUp(authFn: Function) {
  return async (email: string, password: string): Promise<UserCredential> => {
    let result = null;
    try {
      result = await authFn(auth, email, password);
    } catch (e) {
      log.error('Sign In or Sign Up Error', e);
      throw e;
    }

    return result;
  };
}

export const signUp = signInOrSignUp(createUserWithEmailAndPassword);

export const signIn = signInOrSignUp(signInWithEmailAndPassword);

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();

  try {
    await signInWithPopup(auth, provider);
  } catch (error) {
    log.error('Error signing in with Google', error);
  }
}

export async function signOut() {
  try {
    return auth.signOut();
  } catch (error) {
    log.error('Error signing out with Google', error);
  }
}

export async function checkAuth() {
  const authDomain = firebaseConfig.authDomain;
  const cloudFunctionName = authCheckCloudFunction;
  return fetch(`https://${authDomain}/${cloudFunctionName}`, {
    mode: 'cors',
    credentials: 'include',
    method: 'POST',
  })
    .then((res) => res.text())
    .catch((error) => log.error('Request to AuthCheck has failed', error));
}

export async function loginWithCustomToken(customToken: string) {
  return signInWithCustomToken(auth, customToken);
}
