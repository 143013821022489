// Firestore queries for DispatchSchedules collection
import { query, where } from 'firelordjs';

import { compact } from 'lodash';

import { DispatchSchedules } from '@/lib/firebase/db/collections';
import { getUserCompanyId } from '@/lib/firebase/db/helpers';
import { UserDoc } from '@/lib/firebase/db/metaTypes';

export const dispatchScheduleQuery = ({ userDoc }: { userDoc: UserDoc }) => {
  const constraints = compact([
    where('dispatchCompanyId', '==', getUserCompanyId(userDoc)),
  ]);
  return query(DispatchSchedules.collection(), ...constraints);
};
