import { GeoPoint } from 'firelordjs';

import { isUndefined, omitBy } from 'lodash';

import { firestore } from '@/lib/firebase';
import { getCurrentUserDoc } from '@/lib/firebase/auth';
import { DispatchSchedules } from '@/lib/firebase/db/collections';
import {
  getReaderCompaniesArray,
  getUserCompanyId,
} from '@/lib/firebase/db/helpers';
import { DispatchSchedule } from '@/lib/firebase/db/metaTypes';

import { getLatLngByZipCode } from '@/lib/helpers/maps';

/**
 * Saves or updates a dispatch schedule in Firestore based on the provided data. If a dispatch schedule ID is provided,
 * the existing document with that ID is updated. If no ID is provided, a new dispatch schedule document is created.
 * Additional processing is performed to include the dispatch company ID and reader companies array based on the current user's company.
 * If a radius zip code is provided in the data, it is converted to a GeoPoint representing the latitude and longitude of that zip code.
 *
 * @param {string | null} dispatchScheduleId - The ID of the dispatch schedule to update, or null to create a new schedule.
 * @param {Partial<DispatchSchedule['writeFlatten']>} dispatchScheduleData - The data for the dispatch schedule, conforming to its expected structure.
 * @returns {Promise<DispatchScheduleDoc>} A promise that resolves when the dispatch schedule has been successfully saved or updated in Firestore.
 */
export async function saveDispatchSchedule(
  dispatchScheduleId: string | null,
  dispatchScheduleData: Partial<DispatchSchedule['writeFlatten']>
) {
  const userDoc = await getCurrentUserDoc();
  if (!userDoc) {
    return;
  }
  const dispatchCompanyId = getUserCompanyId(userDoc);
  const readerCompanies = getReaderCompaniesArray([dispatchCompanyId]);
  const dataToSave = {
    ...dispatchScheduleData,
    dispatchCompanyId,
    readerCompanies,
  };

  if (!!dataToSave.radiusZipCode) {
    const { lat = 0, lng = 0 } =
      (await getLatLngByZipCode(dataToSave.radiusZipCode)) || {};
    dataToSave.radiusGeoPoint = new GeoPoint(lat, lng);
  } else {
    dataToSave.radiusGeoPoint = null;
  }
  return firestore.save(
    !!dispatchScheduleId
      ? DispatchSchedules.doc(dispatchScheduleId)
      : DispatchSchedules.collection(),
    omitBy(dataToSave, isUndefined),
    userDoc
  );
}
