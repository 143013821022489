// general purpose utility functions

/**
 * Retrieves the global object, depending on the runtime environment.
 * @function
 * @returns {Object} The global object (window in browsers, global in Node.js).
 */
export function getGlobal() {
  if (typeof window !== 'undefined') return window;
  return global;
}

/**
 * Replaces parameters in a route string with corresponding values from a given object.
 * @function
 * @param {string} route - The route string containing parameters to replace.
 * @param {Record<string, string>} params - The object containing parameter-value pairs.
 * @returns {string} The route string with parameters replaced by their values.
 * @example
 * const route = '/user/:userId/profile';
 * const params = { userId: '123' };
 * const replacedRoute = replaceParamsInRoute(route, params);
 * // Returns '/user/123/profile'
 */
export function replaceParamsInRoute(
  route: string,
  params: Record<string, string>
) {
  const PATH_DELIMITER = '/';
  const routeSegments = route.split(PATH_DELIMITER);
  const newSegments = routeSegments.map((segment) => {
    const parameter = segment.substring(1);
    const value = params[parameter];
    return value || segment;
  });

  return newSegments.join(PATH_DELIMITER);
}
