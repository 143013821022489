// Helper functions for Tasks collection
import { TaskDoc } from '@/lib/firebase/db/metaTypes';

/**
 * Determines whether a given task is associated with an invoice.
 *
 * @param {TaskDoc} task - The task document to check for an associated invoice.
 *
 * @returns {boolean} True if the task is associated with an invoice, false otherwise.
 */
export function getIsTaskInvoiced(task: TaskDoc) {
  return !!task.get('invoice');
}
