// Utility functions to manage Map and Geolocation stuff
import { GeoPoint } from 'firelordjs';

import { pickBy } from 'lodash';

import { GEOCODE_URL, GOOGLE_MAPS_API_KEY } from '@/lib/env';

/**
 * Check if param is a valid US ZIP code
 * @param {string} zipCode
 * @returns
 */
export function getIsValidUSZipCode(zipCode = '') {
  const zipCodeRegEx = /^\d{5}(?:[-\s]\d{4})?$/;
  return zipCodeRegEx.test(zipCode);
}

type GeocoderResult = {
  formatted_address: string;
  geometry: { location: LatLng };
  types: string[];
};
type GeocoderStatus = 'OK' | 'ERROR' | 'ZERO_RESULTS';
type GeocoderResponse = {
  results: GeocoderResult[];
  status: GeocoderStatus;
};

/**
 * Mock Geocoder API using HTTP calls to replace client-side library
 * when this is not available, e.g. due to code being run server-side.
 */
export function HTTPGeocoder() {
  return {
    geocode: function (
      { address, latlng }: { address?: string; latlng?: string },
      callback: (results: GeocoderResult[], status: GeocoderStatus) => void
    ) {
      const params = new URLSearchParams(
        pickBy({
          address: address || '',
          latlng: latlng || '',
          key: GOOGLE_MAPS_API_KEY || '',
        })
      );
      fetch(`${GEOCODE_URL}?${params}`)
        .then((res) => res.json())
        .then((data: GeocoderResponse) => {
          const { results, status } = data;
          callback(results, status);
        })
        .catch((error) => {
          return callback([], 'ERROR');
        });
    },
  };
}

export type LatLng = { lat: number; lng: number };
let positionCache: Record<string, LatLng> = {};
/**
 * Retrieves the latitude and longitude based on the provided zip code using geocoding.
 * @async
 * @function
 * @param {string} zipcode - The zip code used to fetch the geographical coordinates.
 * @returns {Promise<LatLng>} A Promise that resolves to an object with 'lat' and 'lng' properties representing the coordinates.
 * @throws {Error} If the geocoding request fails or encounters an error.
 * @example
 * const coordinates = await getLatLngByZipCode('12345');
 * // Returns { lat: 12.345, lng: 67.890 } or undefined
 */
export async function getLatLngByZipCode(
  zipcode: string
): Promise<LatLng | void> {
  const cachedPosition = positionCache[zipcode];
  if (!!cachedPosition) {
    return cachedPosition;
  }

  let geocoder = HTTPGeocoder();
  let address = 'zipcode ' + zipcode;
  let lat: number, lng: number;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      const location = results[0]?.geometry.location;
      if (status === 'OK' && !!location) {
        lat = location?.lat || 0;
        lng = location?.lng || 0;
      } else {
        resolve();
      }

      const position = { lat, lng };
      positionCache[zipcode] = position;

      resolve(position);
    });
  });
}

/**
 * Retrieves a human-readable address name for a given geographic point (latitude and longitude).
 * If the geocoding is successful and an address is found, it is returned; otherwise, an empty string is returned.
 *
 * @param {GeoPoint} geoPoint - An object containing `latitude` and `longitude` properties representing the geographic point.
 * @returns {Promise<string>} A promise that resolves with the formatted address as a string, or an empty string if no address is found or if an error occurs.
 */
export function getAddressNameByGeoPoint(geoPoint: GeoPoint): Promise<string> {
  const geocoder = HTTPGeocoder();
  const latlng = [geoPoint.latitude, geoPoint.longitude].join(',');
  return new Promise((resolve, reject) => {
    geocoder.geocode({ latlng }, (results, status) => {
      const postalCodeResult = results.find((result) =>
        result.types.includes('postal_code')
      );
      const address = postalCodeResult?.formatted_address;
      if (status === 'OK' && !!address) {
        resolve(address);
      } else {
        resolve('');
      }
    });
  });
}

/**
 * Converts a distance in miles to meters.
 *
 * @param {number} miles - The distance in miles to be converted.
 * @returns {number} The distance in meters.
 */
export function milesToMeters(miles: number) {
  return miles * 1609.34;
}
