// Firestore queries for Jobs collection
import { limit, orderBy, query, where } from 'firelordjs';
import { OrderByDirection } from 'firelordjs/dist/types';

import { compact } from 'lodash';

import { Companies, Jobs } from '@/lib/firebase/db/collections';
import { getReaderCompaniesConstraint } from '@/lib/firebase/db/helpers';
import { DispatchScheduleDoc, UserDoc } from '@/lib/firebase/db/metaTypes';

import { getEndOfDay, getStartOfDay } from '@/lib/helpers/dates';

/**
 * Constructs a Firestore query for fetching the most recent jobs.
 * @function
 * @param {Object} [options={}] - The options object.
 * @param {UserDoc} [options.userDoc] - The user document containing reader companies information.
 * @param {number} [options.limitTo=10] - The maximum number of jobs to fetch.
 * @returns {Query} A Firestore query for fetching the most recent jobs.
 */
export const mostRecentJobsQuery = ({
  userDoc,
  limitTo = 10,
}: { userDoc?: UserDoc; limitTo?: number } = {}) => {
  const constraints = compact([
    getReaderCompaniesConstraint(userDoc),
    orderBy('startDatetime', 'desc'),
    limit(limitTo),
  ]);
  return query(Jobs.collection(), ...constraints);
};

export const JOBS_BY_DATE_DEFAULT_SORT_FIELD = 'startDatetime';
export const JOBS_BY_DATE_DEFAULT_SORT_ORDER = 'asc';

/**
 * Constructs a Firestore query for fetching jobs based on a specific date.
 * @function
 * @param {Object} options - The options object.
 * @param {UserDoc} [options.userDoc] - The user document containing reader companies information.
 * @param {Date} options.date - The date to filter the jobs.
 * @returns {Query} A Firestore query for fetching jobs based on the specified date.
 */
export const jobsByDateQuery = ({
  date,
  userDoc,
  sortField = JOBS_BY_DATE_DEFAULT_SORT_FIELD,
  sortOrder = JOBS_BY_DATE_DEFAULT_SORT_ORDER,
}: {
  date: Date;
  userDoc?: UserDoc;
  sortField?: string;
  sortOrder?: OrderByDirection;
}) => {
  const constraints = compact([
    getReaderCompaniesConstraint(userDoc),
    where('startDatetime', '>=', getStartOfDay(date)),
    where('startDatetime', '<=', getEndOfDay(date)),
    orderBy(sortField, sortOrder),
  ]);
  return query(Jobs.collection(), ...constraints);
};

/**
 * Constructs a Firestore query for fetching jobs based on a specific dispatch schedule params and date.
 * @function
 * @param {Object} options - The options object.
 * @param {UserDoc} [options.dispatchScheduleDoc] - The DispatchSchedule document containing query parameters.
 * @param {UserDoc} [options.userDoc] - The user document containing reader companies information.
 * @param {Date} options.date - The date to filter the jobs.
 * @returns {Query} A Firestore query for fetching jobs based on the specified date.
 */
export const jobsForDispatchScheduleQuery = ({
  dispatchScheduleDoc,
  date,
  userDoc,
  sortField = JOBS_BY_DATE_DEFAULT_SORT_FIELD,
  sortOrder = JOBS_BY_DATE_DEFAULT_SORT_ORDER,
}: {
  dispatchScheduleDoc: DispatchScheduleDoc | null;
  date: Date;
  userDoc?: UserDoc;
  sortField?: string;
  sortOrder?: OrderByDirection;
}) => {
  const dispatchScheduleData = dispatchScheduleDoc?.data();
  const constraints = compact([
    !!dispatchScheduleData?.clientsIds?.length &&
      where(
        'client',
        'in',
        dispatchScheduleData.clientsIds.map((clientId) =>
          Companies.doc(clientId)
        )
      ),
  ]);
  return query(
    // Re-use jobsByDate query constraints
    jobsByDateQuery({
      date,
      userDoc,
      sortField,
      sortOrder,
    }),
    ...constraints
  );
};

/**
 * Constructs a Firestore query to find jobs that are associated with a specific dispatch schedule ID.
 * This function creates a query that filters jobs based on their inclusion of a given dispatch schedule ID within an array field in the job documents.
 * It's particularly useful for retrieving all jobs linked to a certain dispatch schedule.
 *
 * @param {Object} params - The parameters object containing the dispatch schedule ID.
 * @param {string} params.dispatchScheduleId - The ID of the dispatch schedule to filter jobs by.
 * @returns {Query} A Firestore query object configured to find jobs that contain the specified dispatch schedule ID in their 'dispatchSchedules' array field.
 */
export const jobsByDispatchScheduleIdQuery = ({
  dispatchScheduleId,
  date,
}: {
  dispatchScheduleId: string;
  date?: Date;
}) => {
  const constraints = compact([
    where('dispatchSchedules', 'array-contains', dispatchScheduleId),
  ]);
  return query(
    !!date ? jobsByDateQuery({ date }) : Jobs.collection(),
    ...constraints
  );
};
