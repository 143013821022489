// Implement custom wrappers for firestore functions to read data from db
import { getDoc, getDocs, onSnapshot } from 'firelordjs';
import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  ErrorOnSnapshotLastArg,
  FirestoreError,
  GeneralQuery,
  GetDoc,
  MetaType,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  SnapshotListenOptions,
  Unsubscribe,
} from 'firelordjs/dist/types';

/**
 * A wrapper function for Firestore's getDoc that excludes documents marked as deleted.
 * If the document fetched by getDoc has a `deleted` field set to true, this function will simulate a non-existing document.
 * It modifies the original DocumentSnapshot to have `exists` return false, and `data` and `get` methods return undefined.
 *
 * @param {DocumentReference} ref - A reference to the Firestore document to fetch.
 * @returns {Promise<DocumentSnapshot>} A promise that resolves with a DocumentSnapshot. If the document is marked as deleted, the snapshot will behave as if the document does not exist.
 */
export const getDocWrapper: GetDoc = async (ref) => {
  const originalDocSnap = await getDoc(ref);
  if (!originalDocSnap.data()?.deleted) {
    return originalDocSnap;
  }
  return {
    ...originalDocSnap,
    exists: () => false,
    data: () => undefined,
    get: () => undefined,
  };
};

/**
 * Filters out documents marked as deleted from an array of Firestore QueryDocumentSnapshots.
 * This function iterates over the array of QueryDocumentSnapshots, excluding those where the `deleted` field is set to true.
 * It returns a new array containing only the documents that are not soft-deleted.
 *
 * @template T Extends MetaType, indicating the expected structure of the documents within the snapshots.
 * @param {QueryDocumentSnapshot<T>[]} docs - An array of QueryDocumentSnapshots obtained from a Firestore query.
 * @returns {QueryDocumentSnapshot<T>[]} A new array of QueryDocumentSnapshots, containing only documents that are not marked as deleted.
 */
export const filterSoftDeletedDocs: <T extends MetaType>(
  docs: QueryDocumentSnapshot<T>[]
) => QueryDocumentSnapshot<T>[] = (docs) =>
  docs.filter((doc) => !doc.data()?.deleted);

/**
 * Filters out documents marked as deleted from a Firestore QuerySnapshot.
 * This function iterates over the documents in a QuerySnapshot, excluding those where the `deleted` field is not falsy.
 * It creates a new QuerySnapshot-like object containing only the documents that are not soft-deleted.
 *
 * Note: The returned object mimics the structure of a Firestore QuerySnapshot but is not an instance of Firestore's QuerySnapshot class.
 *
 * @template T Extends MetaType, indicating the expected structure of the documents within the snapshot.
 * @param {QuerySnapshot<T>} querySnap - The original QuerySnapshot obtained from a Firestore query.
 * @returns {QuerySnapshot<T>} A new object resembling a QuerySnapshot, containing only documents that are not marked as deleted.
 */
export const filterSoftDeletedDocsFromQuerySnapshot: <T extends MetaType>(
  querySnap: QuerySnapshot<T>
) => QuerySnapshot<T> = (querySnap) => {
  const notDeletedDocs = filterSoftDeletedDocs(querySnap.docs);
  const newQuerySnap = {
    ...querySnap,
    docs: notDeletedDocs,
  };
  return newQuerySnap;
};

/**
 * A wrapper function for Firestore's getDocs that filters out documents marked as deleted.
 * This function takes a query and returns a modified QuerySnapshot where documents with a `deleted` field set to true are excluded.
 *
 * @template T Extends MetaType, indicating the expected structure of the documents returned by the query.
 * @param {GeneralQuery<T>} query - The Firestore query to be executed.
 * @returns {Promise<QuerySnapshot<T>>} A promise that resolves with a QuerySnapshot containing only the documents that are not marked as deleted.
 */
export const getDocsWrapper: <T extends MetaType>(
  query: GeneralQuery<T>
) => Promise<QuerySnapshot<T>> = async (query) => {
  const originalQuerySnap = await getDocs(query);
  const newQuerySnap =
    filterSoftDeletedDocsFromQuerySnapshot(originalQuerySnap);
  return newQuerySnap;
};

// Types replicated from firelordjs/dist/types/operations/onSnapshot.d.ts
type Reference<T extends MetaType> =
  | DocumentReference<T>
  | Query<T>
  | CollectionReference<T>;
type ThirdArg = ((error: FirestoreError) => void) | SnapshotListenOptions;
type OnNext = <T extends MetaType, Ref extends Reference<T>>(
  snapshot: Ref extends DocumentReference<T>
    ? DocumentSnapshot<T>
    : QuerySnapshot<T>
) => void | Promise<void>;

/**
 * A wrapper function for Firestore's onSnapshot that automatically filters out documents marked as deleted.
 * This function enhances the standard onSnapshot by providing a custom onNext callback that excludes documents with a `deleted` field set to true.
 * It supports both document and query snapshots, applying the filter only to query snapshots which contain multiple documents.
 *
 * @template T Extends MetaType, indicating the expected structure of the documents within the snapshot.
 * @template Ref Extends Reference<T>, represents the type of Firestore reference (document or collection) being observed.
 * @template Err Extends ThirdArg, an optional error handling callback type.
 *
 * @param {Ref extends never ? Ref : Reference<T>} reference - The Firestore reference (document or collection) to observe.
 * @param {OnNext} onNext - The callback to execute when a new snapshot is available. It receives a snapshot where documents marked as deleted are excluded.
 * @param {Err extends never ? Err : ThirdArg} [onError] - An optional error handling callback.
 * @param {Err extends (error: FirestoreError) => void | Promise<void> ? SnapshotListenOptions : ErrorOnSnapshotLastArg} [options] - Optional settings for the snapshot listener.
 * @returns {Unsubscribe} A function that can be called to unsubscribe from the snapshot listener.
 */
export const onSnapshotWrapper: <
  T extends MetaType,
  Ref extends Reference<T>,
  Err extends ThirdArg,
>(
  reference: Ref extends never ? Ref : Reference<T>,
  onNext: OnNext,
  onError?: Err extends never ? Err : ThirdArg,
  options?: Err extends (error: FirestoreError) => void | Promise<void>
    ? SnapshotListenOptions
    : ErrorOnSnapshotLastArg
) => Unsubscribe = (reference, onNext, onError, options) => {
  const onNextWrapper: OnNext = (snapshot) => {
    // Narrow type of snapshot as QuerySnapshot by checking for 'docs' property
    // https://www.geeksforgeeks.org/typescript-in-operator-narrowing-type/
    if ('docs' in snapshot) {
      const newSnap = filterSoftDeletedDocsFromQuerySnapshot(snapshot);
      return onNext(newSnap);
    } else {
      return onNext(snapshot);
    }
  };
  const unsubscribe = onSnapshot(reference, onNextWrapper, onError, options);
  return unsubscribe;
};
