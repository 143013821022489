import { query, where } from 'firelordjs';

import { Jobs, Tasks } from '@/lib/firebase/db/collections';

/**
 * Constructs a Firestore query to retrieve tasks associated with a specific job.
 *
 * @param {Object} params - The parameters for constructing the query.
 * @param {string} params.jobId - The ID of the job for which to retrieve associated tasks.
 *
 * @returns A Firestore query object that can be used to fetch tasks associated with the specified job ID.
 */
export const tasksByJobQuery = ({ jobId }: { jobId: string }) => {
  return query(Tasks.collection(), where('job', '==', Jobs.doc(jobId)));
};
