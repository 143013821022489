// Export config options that are set on environment variables or configuration files
import firebaseConfigJSON from '../../firebase.json';

export const nodeEnv = process.env.NODE_ENV;

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || '',
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || '',
  emulators: firebaseConfigJSON.emulators,
};

export const reactAppUrl = process.env.NEXT_PUBLIC_REACT_APP_URL || '';
export const reactAppLoginRoute =
  process.env.NEXT_PUBLIC_REACT_APP_LOGIN_ROUTE || '';
export const reactAppJobsListRoute =
  process.env.NEXT_PUBLIC_REACT_APP_JOBS_LIST_ROUTE || '';
export const reactAppJobDetailsRoute =
  process.env.NEXT_PUBLIC_REACT_APP_JOB_DETAILS_ROUTE || '';
export const reactAppJobCreateRoute =
  process.env.NEXT_PUBLIC_REACT_APP_JOB_CREATE_ROUTE || '';
export const reactAppJobEditRoute =
  process.env.NEXT_PUBLIC_REACT_APP_JOB_EDIT_ROUTE || '';
export const reactAppOperatorDetailsRoute =
  process.env.NEXT_PUBLIC_REACT_APP_OPERATOR_DETAILS_ROUTE || '';
export const reactAppTaskDetailsRoute =
  process.env.NEXT_PUBLIC_REACT_APP_TASK_DETAILS_ROUTE || '';

// Name of the cloud function to check the current authentication status
export const authCheckCloudFunction =
  process.env.NEXT_PUBLIC_AUTH_CHECK_FUNCTION;

// Use this flag to determine if should connect firebase sdk to local emulators
export const useEmulators = process.env.NEXT_PUBLIC_USE_EMULATORS === 'true';

export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const GEOCODE_URL = process.env.NEXT_PUBLIC_GEOCODE_URL;

export const ISHEEPDOG_COMPANY_ID =
  process.env.NEXT_PUBLIC_ISHEEPDOG_COMPANY_ID || '';
export const ISD_DISPATCH_COMPANY_ID =
  process.env.NEXT_PUBLIC_ISD_DISPATCH_COMPANY_ID || '';

export const DEFAULT_AVATAR_URL =
  process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL || '';

export const algoliaConfig = {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || '',
  indexNameOperatorTruck:
    process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME_OPERATOR_TRUCK || '',
};
